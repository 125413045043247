function filteredArrayWithString(array, string) {
  if (!array) return []

  let filtered = JSON.parse(JSON.stringify(array))

  if (string === '') {
    return array
  }

  const search = string.toLowerCase().trim()

  filtered = filtered.filter((arrEl) => {
    let searchObjectString = ''

    Object.keys(arrEl).forEach((key) => {
      searchObjectString += ' ' + arrEl[key]
    })

    if (searchObjectString.toLowerCase().indexOf(search) > -1) {
      return true
    }
  })

  return filtered
}


export { filteredArrayWithString }

