<template>
  <tr class="tableRow" @click="openCustomer(customer.id)">
    <td :style="error" v-if="!isMobile">{{ customer.email }}</td>
    <td>{{ customer.name }}</td>
    <td>{{ customer.address }}</td>
    <td v-if="!isMobile">{{ customer.zip }}</td>
    <td v-if="!isMobile">{{ customer.tel }}</td>
    <td v-if="!isMobile">{{ formatDate(new Date(customer.registered)) }}</td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from './../../globalFunctions/date'

export default {
  props: {
    customer: Object
  },
  methods: {
    unslug(string) {
      return (string.charAt(0).toUpperCase() + string.slice(1)).replace("_", " ")
    },
    openCustomer(customerId) {
      this.$router.push({ name: 'OpenCustomer', params: { customerId } })
    },
    formatDate
  },
  computed: {
    error() {
      if (!this.customer.name || !this.customer.email || !this.customer.address || !this.customer.zip || !this.customer.tel) {
        return { "border-left": "3px solid orange" }
      }
    },
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      isMobile: "isMobile"
    }),
  }
};
</script>

<style scoped lang="scss">
.tableRow {
  cursor: pointer;
  border: 3px solid red;
}

.tableRow:hover {
  background: #fafafa;
}
</style>