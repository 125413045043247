<template>
  <div>
    <div class="pageWrapper">
      <div class="topbar">
        <div style="display: flex">
          <H2>Customers</H2>
        </div>
      </div>
      <SearchInput placeholder="Search" v-model="searchString" />
      <table class="standardTable">
        <br />
        <tr class="standardTableHeader">
          <th v-if="!isMobile">Email</th>
          <th>Name</th>
          <th>Address</th>
          <th v-if="!isMobile">ZIP</th>
          <th v-if="!isMobile">Tel</th>
          <th v-if="!isMobile">Registred</th>
        </tr>
        <LoadingRowTable :dataList="customers" :columnsAmount="6">
          <CustomerItem
            :key="customer.id"
            :customer="customer"
            v-for="customer in filtered"
          />
        </LoadingRowTable>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import { filteredArrayWithString } from '@/globalFunctions/filterArrayWithString'

import CustomerItem from '@/components/customers/CustomerItem'
import LoadingRowTable from '@/components/LoadingRowTable'
import SearchInput from '../../layout/SearchInput'
import H2 from '@/layout/typography/H2'

export default {
  data() {
    return {
      customers: undefined,
      searchString: '',
    }
  },
  components: {
    CustomerItem,
    LoadingRowTable,
    SearchInput,
    H2,
  },
  computed: {
    filtered() {
      return filteredArrayWithString(this.customers, this.searchString)
    },
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
  created() {
    axios.post('customers/getCustomers').then((res) => {
      this.customers = res.data
    })
  },
}
</script>

<style scoped lang="scss">
@import url('./../../assets/css/table.css');
</style>